import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { useSelector, useDispatch } from "react-redux";
import { registerNewTkl2Account } from "lib/api/common";

import { formattedMoney } from "lib/helpers/formatters";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { getConsumerPossiblePlans1 } from "lib/api/admin";
import { AddPlanModal } from "../AddPlanModal";
import { PaymentDetailsModal } from "../PaymentDetailsModal";

import { OTPSelectAmountModal } from "../OTPSelectAmountModal";
import moment from "moment";

import {
  fetchSavedDebitCards,
  fetchSavedBankAccounts,
} from "redux/actions/consumerSavedPaymentMethod";

import "./style.scss";

export const PlanSelector = ({ debt, selectedTab }) => {
  const { id } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [consumerPossiblePlan, setConsumerPossiblePlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentType, setPaymentType] = useState("OPT");

  const [isAddPlanModalOpen, setIsAddPlanModalOpen] = useState(false);
  const toggleAddPlan = () => setIsAddPlanModalOpen(!isAddPlanModalOpen);

  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);
  const togglePaymentDetails = () =>
    setIsPaymentDetailsModalOpen(!isPaymentDetailsModalOpen);

  const [isOTPSelectAmountModalOpen, setIsOTPSelectAmountModalOpen] =
    useState(false);
  const toggleOTPSelectAmount = () =>
    setIsOTPSelectAmountModalOpen(!isOTPSelectAmountModalOpen);

  const profileInfo = useSelector((state) => state.profile.profileData.profile);
  const isLitigation = debt?.suit_filed && !debt?.judgment_date;

  useEffect(() => {
    const fetchConsumerPossiblePlan = async () => {
      setLoading(true);
      const response = await getConsumerPossiblePlans1({
        client_id: id,
        debt_id: debt.debt_id,
      });
      if (response.success === "True") {
        setLoading(false);
        setConsumerPossiblePlan(response);
      } else {
        alert.error(response.message);
      }
    };
    fetchConsumerPossiblePlan();
  }, []);

  useEffect(() => {
    if (selectedTab === "1") {
      // dispatch(fetchSavedPayPal(id, debt.referenceNumber));
      dispatch(fetchSavedDebitCards(id, debt.referenceNumber));
      dispatch(fetchSavedBankAccounts(id, debt.referenceNumber));
    }
  }, [selectedTab]);

  const openOPTPaymentModal = async () => {
    if (profileInfo.new_payment_system && !profileInfo.profile_created) {
      setLoading(true);
      const request = await registerNewTkl2Account({ client_id: id });

      if (request.success === "True") {
        setLoading(false);
        setPaymentType("OTP");
        toggleOTPSelectAmount();
      } else {
        setLoading(false);
        alert.error(request.message);
      }
    } else {
      setLoading(false);
      setPaymentType("OTP");
      toggleOTPSelectAmount();
    }
  };

  const openAddPlanModal = () => {
    toggleAddPlan();
  };

  const selectPlan = async (selectedPlan) => {
    if (profileInfo.new_payment_system && !profileInfo.profile_created) {
      setLoading(true);
      const request = await registerNewTkl2Account({ client_id: id });

      if (request.success === "True") {
        setLoading(false);
        setSelectedPlan(selectedPlan);
        setPaymentType("PLAN");

        togglePaymentDetails();
      } else {
        setLoading(false);
        alert.error(request.message);
      }
    } else {
      setSelectedPlan(selectedPlan);
      setPaymentType("PLAN");

      togglePaymentDetails();
    }
  };

  return (
    <>
      {loading ? <LoadingSpinner middleFixed={true} /> : null}
      <div className="plan-selector-container">
        <div className="header-container">
          <div className="header">Payment Plans</div>
          <div className="btn-container">
            <CustomButton
              type="secondary"
              optionalClass="otp-btn"
              text="Make a One-Time Payment"
              onClickHandler={() => openOPTPaymentModal()}
            />
            <CustomButton
              text="Add Plan"
              onClickHandler={() => openAddPlanModal()}
            />
          </div>
        </div>
        {consumerPossiblePlan?.data?.single_payment_plans.length > 0 ? (
          <div className="possible-plan-container">
            <div className="block short">
              <div className="label">
                {
                  consumerPossiblePlan?.data?.single_payment_plans[0]
                    .credit_product_name
                }
              </div>
              <div className="value">
                $
                {formattedMoney(
                  consumerPossiblePlan?.data?.single_payment_plans[0].per_month
                )}
              </div>
            </div>
            <div className="block short">
              <div className="label">Payments</div>
              <div className="value">
                {consumerPossiblePlan?.data?.single_payment_plans[0].payments}
              </div>
            </div>
            <div className="block">
              <div className="label">Discount</div>
              <div className="value">
                $
                {formattedMoney(
                  consumerPossiblePlan?.data?.single_payment_plans[0].discount
                )}{" "}
                (
                {
                  consumerPossiblePlan?.data?.single_payment_plans[0]
                    .discount_percentage
                }
                %)
              </div>
            </div>
            <div className="block">
              <div className="label">Total</div>
              <div className="value">
                $
                {formattedMoney(
                  consumerPossiblePlan?.data?.single_payment_plans[0]
                    .total_amount
                )}
              </div>
            </div>
            <div className="block">
              {consumerPossiblePlan?.data?.single_payment_plans[0]
                .plan_expiration && (
                <>
                  <div className="label">Expiration Date</div>
                  <div className="value">
                    {moment(
                      consumerPossiblePlan?.data?.single_payment_plans[0]
                        .plan_expiration
                    ).format("ll")}
                  </div>
                </>
              )}
            </div>
            <Button
              className="select-plan-btn"
              onClick={() =>
                selectPlan(consumerPossiblePlan?.data?.single_payment_plans[0])
              }
            >
              Select Plan
            </Button>
          </div>
        ) : null}
        {consumerPossiblePlan?.data?.multiple_payment_plans.map((item, i) => (
          <div
            key={i}
            className={`possible-plan-container${
              item.is_open_ended ? " open-pan" : ""
            }`}
          >
            <div className="block short">
              <div className="label">{item.credit_product_name}</div>
              <div className="value">${formattedMoney(item.per_month)}</div>
            </div>
            <div className="block short">
              <div className="label">Payments</div>
              <div className="value">{item.payments}</div>
            </div>
            <div className="block">
              <div className="label">Discount</div>
              <div className="value">
                ${formattedMoney(item.discount)} ({item.discount_percentage}%)
              </div>
            </div>
            <div className="block">
              <div className="label">Total</div>
              <div className="value">${formattedMoney(item.total_amount)}</div>
            </div>
            <div className="block">
              {item.plan_expiration && (
                <>
                  <div className="label">Expiration Date</div>
                  <div className="value">
                    {moment(item.plan_expiration).format("ll")}
                  </div>
                </>
              )}
            </div>
            <Button
              className="select-plan-btn"
              onClick={() => selectPlan(item)}
            >
              Select Plan
            </Button>
          </div>
        ))}
      </div>
      {/* {isAddPlanModalOpen && ( */}
      <AddPlanModal
        isModalOpen={isAddPlanModalOpen}
        toggle={toggleAddPlan}
        debtId={debt.debt_id}
        reference={debt.referenceNumber}
        isLitigation={isLitigation}
      />
      {/* )} */}
      {paymentType === "OTP" && (
        <OTPSelectAmountModal
          isModalOpen={isOTPSelectAmountModalOpen}
          toggle={toggleOTPSelectAmount}
          debt={debt}
        />
      )}
      {paymentType === "PLAN" && (
        <PaymentDetailsModal
          isModalOpen={isPaymentDetailsModalOpen}
          toggle={togglePaymentDetails}
          selectedPlan={selectedPlan}
          debt={debt}
          isChangePaymentMethod={false}
        />
      )}
    </>
  );
};
