import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { adminTabs } from "./adminTabsData";
import { InfoTooltip } from "../InfoTooltip";
import "./style.scss";
import { returnLastFiveChar } from "lib/helpers/formatters";

export const ConsumersNavigation = ({ activeTab, toggle, isAdmin }) => {
  const profileData = useSelector((state) => state.profile.profileData);
  const profileLoading = useSelector((state) => state.profile.loading);
  const activeDebts = useSelector((state) => state.consumerDebts.activeDebts);
  const paymentDebts = useSelector((state) => state.consumerDebts.paymentDebts);
  const establishedDebts = useSelector(
    (state) => state.consumerDebts.establishedDebts
  );
  const paidoffDebts = useSelector((state) => state.consumerDebts.paidoffDebts);
  const recalledDebts = useSelector(
    (state) => state.consumerDebts.recalledDebts
  );
  const tenantName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const isTromberg = tenantName === "TMP";
  const isConsumerProfileComplete = useSelector(
    (state) => state.profile.isConsumerProfileComplete
  );

  const [consumerTabs, setConsumerTabs] = useState([
    { title: "Pending", count: 0, tabId: "1" },
    { title: "Established", count: 0, tabId: "2" },
    { title: "Resolved", count: 0, tabId: "3" },
    { title: "Recalled", count: 0, tabId: "4" },
  ]);

  useEffect(() => {
    setConsumerTabs([
      { title: "Pending", count: activeDebts.length, tabId: "1" },
      { title: "Established", count: establishedDebts.length, tabId: "2" },
      { title: "Resolved", count: paidoffDebts.length, tabId: "3" },
      { title: "Recalled", count: recalledDebts.length, tabId: "4" },
    ]);
  }, [
    paymentDebts,
    activeDebts,
    establishedDebts,
    paidoffDebts,
    recalledDebts,
  ]);

  const setWindowHash = (hash) => {
    if (hash === "Resolved") {
      window.location.hash = "paid_off";
    } else {
      window.location.hash = `${hash.toLowerCase()}`;
    }
  };

  return (
    <div className="consumers-navigation">
      <Nav tabs>
        {isAdmin
          ? adminTabs.map((tab) => (
              <NavItem key={tab.tabId}>
                <NavLink
                  className={classnames({ active: activeTab === tab.tabId })}
                  onClick={() => {
                    toggle(tab.tabId);
                    setWindowHash(tab.title);
                  }}
                >
                  {/* {tab.icon} */}
                  {tab.title}
                  {tab.title === "Profile" && !isConsumerProfileComplete ? (
                    <InfoTooltip title="Consumer profile is incomplete! Click here to finish out consumer profile." />
                  ) : null}
                </NavLink>
              </NavItem>
            ))
          : consumerTabs.map((tab) => (
              <NavItem key={tab.tabId}>
                <NavLink
                  className={classnames({ active: activeTab === tab.tabId })}
                  onClick={() => {
                    toggle(tab.tabId);
                    setWindowHash(tab.title);
                  }}
                >
                  {tab.title}
                  <span className="accounts-count">{tab.count}</span>
                </NavLink>
              </NavItem>
            ))}
      </Nav>
      {!profileLoading && (
        <div className="profile-info">
          <div className="profile-info__row">
            <span className="label ssn">DOB</span>
            <span className="value">
              <span className="value">{profileData?.profile?.dob}</span>
            </span>
          </div>
          <div className="profile-info__row">
            <span className="label ssn">SSN</span>
            <span className="value">
              {profileData.profile &&
                returnLastFiveChar(profileData.profile.ssn)}
            </span>
          </div>
          {isTromberg && (
            <div className="profile-info__row">
              <span className="label ssn">ADDRESS</span>
              <span className="value">{profileData.profile?.street}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ConsumersNavigation.propTypes = {
  activeTab: PropTypes.string,
  toggle: PropTypes.func,
  isAdmin: PropTypes.bool,
};
