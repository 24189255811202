import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  consumerFormPrimaryItems,
  consumerFormPhoneNumbersItems,
  consumerFormAddressItems,
} from "./consumerItems";
import _ from "lodash";
import { ProfileFormInput } from "../ProfileFormInput";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

import {
  formattingPhoneNumber,
  formattingZipCode
} from "lib/helpers/formatters";
import { updateProfileForm } from "redux/actions/profile";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ProfileFormStates } from "../ProfileFormStates";
import { validateEmail } from "lib/helpers/validations";

import "./style.scss"


export const ConsumerProfileForm = () => {
  const dispatch = useDispatch();
  const [profileForm, setProfileForm] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState();

  const consumersData = useSelector((state) => state.profile.profileData);

  const isEditingProfile = useSelector(
    (state) => state.profile.isEditingProfile
  );

  useEffect(() => {
    setProfileForm(_.omit(consumersData.profile, ["client_id"]));

    setSelectedLanguage(profileForm?.language);

  }, [consumersData]);

  const loading = useSelector((state) => state.profile.loading);

  const handleChange = (value) => {
    if (value.cell_phone === "") {
      dispatch(updateProfileForm({ ...profileForm, cell_phone: null }));
    } else if (value.home_phone === "") {
      dispatch(updateProfileForm({ ...profileForm, home_phone: null }));
    } else {
      dispatch(updateProfileForm({ ...profileForm, ...value }));
    }

    setProfileForm({ ...profileForm, ...value });
  };

  const handleStateChange = (e) => {
    dispatch(updateProfileForm({ ...profileForm, state: e.target.value }));
    setProfileForm({ ...profileForm, state: e.target.value });
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);

    dispatch(updateProfileForm({ ...profileForm, language: e.target.value }));
    setProfileForm({ ...profileForm, language: e.target.value });
  };

  const handleOtherLanguage = (value) => {
    
    if (value?.other_language.length >= 20) {
      value.other_language = value.other_language.slice(0, 20);
    }

    setProfileForm({ ...profileForm, ...value });

    dispatch(updateProfileForm({ ...profileForm, language: value.other_language }));
  }

  const renderPrimaryInformation = () => {
    return consumerFormPrimaryItems.map((item) => {

      if (item.title.toLowerCase() === "alias") {
        <div className="profile-form-group form-group" key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={!isEditingProfile || item.disabled}
            isInvalid={!isValidField(item.title, profileForm[item.name])}
            isEditingProfile={isEditingProfile}
          />
        </div>
      }

      if (item.title.toLowerCase() === "birth date") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              Birth Date
            </label>
            <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
              {profileForm.dob}
            </div>
          </div>
        );
      }

      if (item.title.toLowerCase() === "language") {
        const languageList = [
          { lang: "English" },
          { lang: "Español" },
          { lang: "Other"}
        ]

        if (profileForm[item.name] !== "English" && profileForm[item.name] !== "Español") {

          return (
            <div className="profile-form-group form-group" key={item.name}>
              <label className="heading heading-caption-bold" htmlFor={item.name}>
                Language
              </label>
              <Select
                labelId="language"
                id="language"
                onChange={handleLanguageChange}
                input={
                  <InputBase
                    value="Other"
                    className="input input-medium"
                  />
                }
              >
                {languageList.map((state, i) => (
                  <MenuItem key={i} value={state.lang} name={state.lang}>
                    {state.lang}
                  </MenuItem>
                ))}
              </Select>
            </div>
          );

        } else {

          return (
            <div className="profile-form-group form-group" key={item.name}>
              <label className="heading heading-caption-bold" htmlFor={item.name}>
                Language
              </label>
              <Select
                labelId="language"
                id="language"
                onChange={handleLanguageChange}
                input={
                  <InputBase
                    value={profileForm && profileForm[item.name]}
                    className="input input-medium"
                  />
                }
              >
                {languageList.map((state, i) => (
                  <MenuItem key={i} value={state.lang} name={state.lang}>
                    {state.lang}
                  </MenuItem>
                ))}
              </Select>
            </div>
          );
        }
      }


      if (item.title.toLowerCase() === "other language") {
        if (selectedLanguage !== "English" && selectedLanguage !== "Español") {
          if (profileForm.other_language !== undefined) {
            return (
              <div className="profile-form-group form-group" key={item.name}>
                <label className="heading heading-caption-bold" htmlFor={item.name}>
                  {item.title}
                </label>
                <ProfileFormInput
                  value={profileForm && profileForm[item.name]}
                  name={item.name}
                  handleChange={handleOtherLanguage}
                  disabled={!isEditingProfile || item.disabled}
                  isInvalid={!isValidField(item.title, profileForm[item.name])}
                  isEditingProfile={isEditingProfile}
                />
              </div>
            )
          } else {
            return (
              <div className="profile-form-group form-group" key={item.name}>
                <label className="heading heading-caption-bold" htmlFor={item.name}>
                  {item.title}
                </label>
                <ProfileFormInput
                  value={profileForm && profileForm.language}
                  name={item.name}
                  handleChange={handleOtherLanguage}
                  disabled={!isEditingProfile || item.disabled}
                  isInvalid={!isValidField(item.title, profileForm[item.name])}
                  isEditingProfile={isEditingProfile}
                />
              </div>
            )
          }
        } else {
          return null
        }
      }

      return (
        <div className="profile-form-group form-group" key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={!isEditingProfile || item.disabled}
            isInvalid={!isValidField(item.title, profileForm[item.name])}
            isEditingProfile={isEditingProfile}
          />
        </div>
      );
    });
  };

  const renderPreviewModePrimaryInfo = () => {
    return consumerFormPrimaryItems.map((item) => {
      const [itemValue] = Object.values(
        _.pickBy(consumersData.profile, function (value, key) {
          return _.startsWith(key, item.name);
        })
      );

      if (item.title.toLowerCase() === "alias") {
        if (itemValue !== null && itemValue !== "") {
          return (
            <div className="preview-mode-row" key={item.name}>
              <div className="preview-mode-label" htmlFor={item.name}>
                {item.title}
              </div>
              <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
                {itemValue}
              </div>
            </div>
          )
        } else {
          return null;
        }
      }

      if (item.title.toLowerCase() === "birth date") {
        return (
          <div className="preview-mode-row" key={item.name}>
            <div className="preview-mode-label" htmlFor={item.name}>
              Birth Date
            </div>
            <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
              {profileForm.dob}
            </div>
          </div>
        );
      }

      if (item.title.toLowerCase() === "language") {
        return (
          <div className="preview-mode-row" key={item.name}>
            <div className="preview-mode-label" htmlFor={item.name}>
              {item.title}
            </div>
            <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
              {itemValue === null || itemValue === "" ? "English" : itemValue}
            </div>
          </div>
        );
      }

      if (item.title.toLowerCase() === "other language") {
        return null
      }

      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
            {itemValue}
          </div>
        </div>
      );
    });
  }

  const renderPhoneNumbers = () => {
    return consumerFormPhoneNumbersItems.map((item) => {
      const [itemValue] = Object.values(
        _.pickBy(consumersData.profile, function (value, key) {
          return _.startsWith(key, item.name);
        })
      );

      return (
        <div className="profile-form-group form-group" key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && formattingPhoneNumber(profileForm[item.name])}
            name={item.name}
            handleChange={handleChange}
            validator={formattingPhoneNumber}
            prevValue={itemValue}
            disabled={!isEditingProfile || item.disabled}
            isInvalid={!isValidField(item.title, profileForm[item.name])}
          />
        </div>
      );
    });
  };

  const renderPreviewModePhoneNumber = () => {
    return consumerFormPhoneNumbersItems.map((item) => {
      const [itemValue] = Object.values(
        _.pickBy(consumersData.profile, function (value, key) {
          return _.startsWith(key, item.name);
        })
      );

      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
            {formattingPhoneNumber(itemValue)}
          </div>
        </div>
      );
    });
  }

  const isValidField = (title, value) => {
    if (title === "Cell Phone" && !value && !profileForm.home_phone) {
      return false;
    }

    if (title === "Home Phone" && !value && !profileForm.cell_phone) {
      return false;
    }

    if (title === "Email" && (!value || !validateEmail(value))) {
      return false;
    }

    if (title === "City" && !value) {
      return false;
    }

    if (title === "Street" && !value) {
      return false;
    }

    if (title === "ZIP" && !value) {
      return false;
    }

    return true;
  };

  const renderAdress = () => {
    return consumerFormAddressItems.map((item) => {

      const formGroupClass = () => {
        if (item.title.toLowerCase() === "street") {
          return "profile-form-group form-group profile-form-group--street";
        } else if (item.title.toLowerCase() === "zip") {
          return "profile-form-group form-group profile-form-group--zip";
        } else {
          return "profile-form-group form-group";
        }
      };

      if (item.title.toLowerCase() === "state") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="state">
              State
            </label>
            <ProfileFormStates
              value={profileForm && profileForm[item.name]}
              disabled={!isEditingProfile}
              handleStateChange={handleStateChange}
            />
          </div>
        );
      }

      if (item.title.toLowerCase() === "zip") {
        // validator = validatedZip;
        return (
          <div className={formGroupClass()} key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={profileForm && formattingZipCode(profileForm[item.name])}
              name={item.name}
              handleChange={handleChange}
              // validator={validator}
              disabled={!isEditingProfile || item.disabled}
              isInvalid={!isValidField(item.title, profileForm[item.name])}
            />
          </div>
        );
      }

      return (
        <div className={formGroupClass()} key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            // validator={validator}
            disabled={!isEditingProfile || item.disabled}
            isInvalid={!isValidField(item.title, profileForm[item.name])}
          />
        </div>
      );
    });
  };

  const renderPreviewModeAdress = () => {
    return consumerFormAddressItems.map((item) => {

      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
            {profileForm && profileForm[item.name]}
          </div>
        </div>
      );
    });
  }

  if (_.isEmpty(profileForm) || loading) {
    return <LoadingSpinner middleFixed={true}/>;
  }

  return (
    <div className="profile-form__body">
      <div className={isEditingProfile ? "profile-form__block edit-mode-block" : "profile-form__block"}>
        <h6 className="heading profile-title">General</h6>
        {isEditingProfile 
          ? <div className="profile-form__container">{renderPrimaryInformation()}</div>
          : <div className="profile-preview-mode">{renderPreviewModePrimaryInfo()}</div>
        }
      </div>

      <div className={isEditingProfile ? "profile-form__block edit-mode-block" : "profile-form__block"}>
        <h6 className="heading profile-title">Phone Numbers</h6>
        {isEditingProfile
          ? <div className="profile-form__container">{renderPhoneNumbers()}</div>
          : <div className="profile-preview-mode">{renderPreviewModePhoneNumber()}</div>
        }
      </div>

      <div className={isEditingProfile ? "profile-form__block edit-mode-block" : "profile-form__block"}>
        <h6 className="heading profile-title">Address</h6>
        {isEditingProfile
          ? <div className="profile-form__container">{renderAdress()}</div>
          : <div className="profile-preview-mode">{renderPreviewModeAdress()}</div>
        }
      </div>
    </div>
  );
};
