export const formatedConsumersData = (data, content) => {
  return data.map((item) => ({
    id: item.client_id,
    fullName: `${item.full_name}`.trim(),
    SSN: item.ssn,
    ...(content?.table?.client && { client: item.client }),
    cellPhone: item.cell_phone,
    address: item.address || "",
    dateOfBirth: item.dob || "",
    orginator: item.orginator,
    agent_profile: item.agent_profile?.full_name,
    reference: item.reference,
    amount: item.amount,
    ...(content?.table?.accountStatus && {
      account_status: item.account_status,
    }),
    status: item.debt_status,
  }));
};
